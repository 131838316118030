<template>
  <div class="container">
    <PublicHeader :totalName="$t('bank').title" />
    <div class="card" @click="goRouter" v-if="show">
      <div v-if="IsBindBank">
        <p class="card-no">{{ showBankCard(userInfo.CardNo) }}</p>
        <div class="name">
          <span> {{ bankArr | showName(userInfo.OpenOfBankId) }}</span>
          <span>{{ userInfo.CardUserName }}</span>
        </div>
        <p v-if="userInfo.OpenBank">{{ showLastString(userInfo.OpenBank) }}</p>
      </div>
      <div v-else class="noBank">
        <van-icon name="add-o" size="30" />
      </div>
    </div>
    <div v-if="IsBindBank && !basicConfig.IsEditBank" class="info">
      {{ $t('bank').info }}
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      IsBindBank: false,
      userInfo: {},
      bankArr: [],
      show: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    goRouter() {
      if (!this.IsBindBank || this.basicConfig.IsEditBank) {
        this.$router.push({ name: 'EditBank' })
      }
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.userInfo = res
      if (res.IsBindBank) {
        this.IsBindBank = true
      }
      this.show = true
    },
    showBankCard(CardNo) {
      return '**** **** **** ' + CardNo.substr(-4)
    },
    showLastString(str) {
      return new Array(str.length).join('*') + str.substr(-1);
    },
    async getOpenBank() {
      this.bankArr = await userApi.getOpenBank()
    },
  },
  mounted() {
    this.getUserInfo()
    this.getOpenBank()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 12px
  color #fae39c
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  text-align left
  .card
    margin 13px 15px
    padding 25px 25px 0
    background url('~@/assets/bankbg.jpg') no-repeat
    height 150px
    border-radius 10px
    .card-no
      font-size 17px
      font-weight bold
    .name
      margin 55px 0 10px
      display flex
      justify-content space-between
      font-size 14px
  .noBank
    margin-top -17px
    flex-column()
    height 100%
  .info
    margin 20px
    color #e60d0d
</style>
